// Scripts librairies
import { docReady } from './js/libs/utils'

// Charge la base commune
require('./common.js')

function test () {
  import(/* webpackChunkName: "test", webpackPrefetch: true */ './js/components/test.js').then(() => {
    console.log('loaded')
  })
}

docReady(function () {
  const clickMe = document.getElementById('clickme')
  if (clickMe) {
    clickMe.addEventListener('click', () => {
      test()
    })
  }
})
